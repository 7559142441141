<template>
  <div class="main">
    <div class="title">Здравствуйте, {{ user.fullName }}</div>

    <div class="wrapper-main">
      <div class="!mr-4 min-w-[400px]">
        <div class="steps-faq !mb-4">
          <div class="title">С чего начать работу с Eddy24</div>

          <div class="steps">
            <div class="step">
              <div class="count">
                <div class="num">1</div>
              </div>

              <div class="title">Добавитьте сотрудников</div>
              <!-- <a :href="link" class="button-faq">Смотреть видео</a> -->
            </div>

            <div class="step">
              <div class="count">
                <div class="num">2</div>
              </div>

              <div class="title">Создайте тест</div>
              <!-- <a :href="link" class="button-faq">Смотреть видео</a> -->
            </div>

            <div class="step">
              <div class="count">
                <div class="num">3</div>
              </div>

              <div class="title">Создайте статью</div>
              <!-- <a :href="link" class="button-faq">Смотреть видео</a> -->
            </div>

            <div class="step">
              <div class="count">
                <div class="num">4</div>
              </div>

              <div class="title">Создайте курс</div>
              <!-- <a :href="link" class="button-faq">Смотреть видео</a> -->
            </div>

          </div>
        </div>

        <ApiRequest />
      </div>
      <LastUpdate />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LastUpdate from '@/components/base/LastUpdate.vue'
import ApiRequest from '@/components/redesign/base/ApiRequest';

export default {
  name: 'MainPage',
  components: {
    LastUpdate,
    ApiRequest,
  },
  computed: {
    ...mapState('main', ['categories', 'user', 'tests']),
  },
}
</script>

<style lang="scss">
.main {
  width: 100%;

  & > .title {
    font-weight: 500;
    font-size: 24px;
    line-height: 16px;
    margin-bottom: 30px;
  }

  .wrapper-main {
    width: 100%;
    display: flex;
  }

  .steps-faq {
    width: 100%;
    background: #FFFFFF;
    border-radius: 15px;
    padding: 30px;

    & > .title {
      font-weight: 500;
      font-size: 18px;
      line-height: 16px;
      margin-bottom: 30px;
    }

    .steps {
      .step {
        display: flex;
        align-items: center;
        padding-bottom: 64px;
        position: relative;

        &::after {
          content: "------------";
          position: absolute;
          transform: rotate(90deg);
          color: rgba(57, 162, 215, 0.5);
          top: calc(100% - 42px);
          left: -12px;
        }

        .count {
          margin-right: 20px;

          .num {
            width: 40px;
            height: 40px;
            color: #39A2D7;
            background: rgba(57, 162, 215, 0.1);
            border-radius: 100px;
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            font-size: 19px;
            line-height: 16px;
          }
        }

        & > .title {
          font-weight: 400;
          font-size: 16px;
          line-height: 16px;
          margin-right: 30px;
        }

        .button-faq {
          color: #157BAE;
          background: rgba(57, 162, 215, 0.1);
          border-radius: 8px;
          height: 33px;
          padding: 0 16px;
          display: flex;
          align-items: center;
        }

        &:last-child {
          padding-bottom: 0;
          &::after {
            content: "";
          }
        }
      }
    }
  }
}
</style>
