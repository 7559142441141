<template>
  <div class="last-update">
    <div class="title">Последние обновления</div>

    <div class="list">
      <div
        v-for="(update, index) of updates"
        :key="index"
        class="block-update"
      >
        <div class="title">{{ update.title }}</div>

        <ul>
          <li
            v-for="(item, key) of update.list"
            :key="key"
          >
            {{ item }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LastUpdate',
  data() {
    return {
      updates: [
        {
          title: '7 февраля 2023',
          list: [
            'Добавлен раздел курсы в котором вы можете создавать курсы и назначать их сотрудникам',
            'Добавлена возможность создавать содержание курса',
            'Добавлена База знаний Beta. Базу знаний уже можно заполнять, в скором времени базу знаний сможет использовать пользователь.',
          ],
        },
        {
          title: '2 марта 2023',
          list: [
            'Обновлен раздел сотрудники',
            'Bugfix создания материалов ',
            'Bugfix создание курса',
            'Добавлена возможность добавлять разделы внутри разделов в Базу знаний',
            'Добавлена возможность временно отключить курс',
            'В личном кабинете пользователя можно просматривать прогресс прохождения курсов',
            'В личном кабинете пользователя добавлена возможно вернуться к прохождению курса',
            'В личном кабинете пользователя добавлена возможность изменить свои данные и пароль',
          ],
        },
        {
          title: '10 марта 2023',
          list: [
            'Добавлен прогресс сотрудника по курсам на странице сотрудника',
            'Добавлена история прохождения сотрудника по тестам на странице сотрудника',
            'Bugfix по содержанию курса',
            'Bugfix в личном кабинете пользователя',
          ],
        },
        {
          title: '17 марта 2023',
          list: [
            'Убрана возможно назначения курса всем сотрудниками, при пустом списке сотрудников в курсе',
            'Добавлена возможность добавлять видео с youtube с описанием',
            'Из карточки сотрудника можно отправить пароль сотруднику на почту',
            'Bugfix в личном кабинете пользователя',
            'Bugfix с созданием тестов и материалов',
            'В меню добавлен блок с копированием ссылки на сайт для сотрудника',
            'Редизайн страни Материалы, создание материала и изменение материала'
          ],
        },
        {
          title: '24 марта 2023',
          list: [
            'В материалы добавлены теги, которые позволяют ориентироваться в списке материалов',
            'В материал  добавлен блок с кодом, который позволяет добавлять в материал разный код',
            'В статистике добавлена возможность сортировать результаты по сотруднику',
            'Bugfix создание материалов ',
            'Bugfix истроии прохождения на странице пользователя'
          ],
        },
        {
          title: '13 апреля 2023',
          list: [
            'Редизайн страницы списка тестов. Добавление поиска',
            'Редизайн страницы теста. Удаление неиспользуемых полей, улучшение интерефейса',
            'Добавлен раздел "База знаний". Позволяет структурированно хранить материалы',
            'База знаний добавлена в приложение для сотрудника',
            'Добавлние файлов(фото, видео) в материалах базы знаний',
            'Bugfix создания пользователя',
            'Отправка сообщения с паролем пользователю на email'
          ],
        },
        {
          title: '05 июля 2023',
          list: [
            'Добавление выбора множественны ответов в тестах',
            'Добавилена возможно заполнение ответа текстом в тестах',
            'Bugfix с попытками в тесте',
          ],
        },
        {
          title: '11 января 2024',
          list: [
            'Редизайн страницы сотрудника',
            'Добавиление прогресс сотрудника по курсам',
            'Bugfix статистики и создание материала',
          ],
        },
        {
          title: '24 января 2024',
          list: [
            'Добавлен поиск в базу знаний',
            'Добавлена интеграция с CRM системой Битркс24',
            'Bugfix с тестами',
          ],
        },
        {
          title: '18 февраль 2024',
          list: [
            'Добавлено копирование статьи в базе знаний',
            'Добавлена блок с кодом в материал и базе знаний',
            'Добавлены теги и описание в материале',
            'Bugfix базы знаний',
          ],
        },
      ].reverse(),
    };
  },
};
</script>

<style lang="scss">
.last-update {
  width: 100%;
  background: #FFFFFF;
  border-radius: 15px;
  padding: 30px 25px;

  .title {
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    margin-bottom: 25px;
  }

  .list {
    .block-update {
      background: rgba(188, 188, 188, 0.1);
      border-radius: 16px;
      padding: 20px;
      margin-bottom: 16px;

      .title {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 22px;
      }

      ul {
        margin-bottom: 0;

        li {
          padding-bottom: 10px;

          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
