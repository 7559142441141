<template>
  <div
    class="w-full flex p-[20px] bg-[#fff] shadow-sm rounded-xl"
  >
    <div class="!pr-3 max-w-[230px]">
      <div class="text-2xl font-bold">
        REST API
      </div>
      <div class="text-[14px] !mb-4 text-[#999999]">
        Добавление тестов и получение статистики через API
      </div>

      <button
        class="btn btn-outline"
        @click="onCreateRequest"
      >
        Сделать запрос
      </button>
    </div>
    <UIcon name="api" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ApiRequest',
  computed: {
    ...mapState('main', ['user']),
  },
  methods: {
    async onCreateRequest() {
      this.$toast('Запрос на доступ к API отправлен. Скоро c вами свяжется наш менеджер')

      const { email, phone, fullName } = this.user

      await fetch('https://back.b24apps.ru/stockwell/app/tmwx/create-lead-app/request.php', {
        method: 'POST',
        body: JSON.stringify({
          title: `Eddy24(${this.user.email}): Запрос на получение API`,
          name: fullName,
          phone,
          email,
          portal: '',
        }),
      })
    },
  },
}
</script>

<style lang="scss">
.btn.btn-outline {
  color: var(--main);
  background: #006cb71a;
}
</style>
